import { useContext } from 'react'

import isClient from '@components/common/isClient'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import ServerSidePropsContext from '@pages/next/App/context/serverSidePropsContext'
import { mediaQueries } from '@themes/mediaQueries'

export default () => {
  const serverSideProps = useContext(ServerSidePropsContext)
  const mediaQueryIsMobile = useMediaQuery(mediaQueries.mobile)

  return isClient ? mediaQueryIsMobile : serverSideProps.isMobile
}
