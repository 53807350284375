import dynamic from 'next/dynamic'
import type { ComponentType } from 'react'

import { BlocksKeys } from '@redux/features/homePage/constants/blockHomePage.constants'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import NodeCustomContentBock from '@components/routesPage/components/nodeCustomContentBock/NodeCustomContentBock'

const About = dynamic(() => import('@components/mainPageBlocks/About'))
const AboutWithVideo = dynamic(() => import('@components/mainPageBlocks/AboutWithVideo'))
const Advantages = dynamic(() => import('@components/mainPageBlocks/Advantages'))
const Carriers = dynamic(() => import('@components/mainPageBlocks/Carriers'))
const PaymentMethodsBlock = dynamic(() => import('@components/mainPageBlocks/PaymentMethodsBlock'))
const PopularRoutes = dynamic(() => import('@components/mainPageBlocks/PopularRoutes'))
const Review = dynamic(() => import('@components/mainPageBlocks/Review'))
const Benefits = dynamic(() => import('@components/mainPageBlocks/Benefits'))
const HighSpeedAwards = dynamic(() => import('@components/mainPageBlocks/HighSpeedAwards'))
const PopularQuestions = dynamic(() => import('@components/mainPageBlocks/PopularQuestions'))
const AgencyDisclaimer = dynamic(() => import('@components/mainPageBlocks/AgencyDisclaimer'))
const BenefitsWithImages = dynamic(() => import('@components/mainPageBlocks/BenefitsWithImages'))
const BenefitsMultipleText = dynamic(() => import('@components/mainPageBlocks/BenefitsMultipleText'))
const SingleImage = dynamic(() => import('@components/mainPageBlocks/SingleImage'))
const ApplicationLinks = dynamic(() => import('@components/mainPageBlocks/ApplicationLinks'))

type PageBlockListType = {
  [key in BlocksKeys]: ComponentType<{ data: ExtendBlocksType }>
}

export const pageBlockList: PageBlockListType = {
  [BlocksKeys.ABOUT]: About,
  [BlocksKeys.ABOUT_WITH_VIDEO]: AboutWithVideo,
  [BlocksKeys.ADVANTAGES_TWO]: Advantages,
  [BlocksKeys.APPLICATION_LINKS]: ApplicationLinks,
  [BlocksKeys.AWARDS]: HighSpeedAwards,
  [BlocksKeys.BENEFITS_WITH_IMAGES]: BenefitsWithImages,
  [BlocksKeys.BLOCK_WITH_MENU]: PopularRoutes,
  [BlocksKeys.BODY_WITH_BENEFITS]: AgencyDisclaimer,
  [BlocksKeys.CARRIERS]: Carriers,
  [BlocksKeys.NODE_CUSTOM_CONTENT_ID]: NodeCustomContentBock,
  [BlocksKeys.PAYMENT_METHODS]: PaymentMethodsBlock,
  [BlocksKeys.POPULAR_QUESTION]: PopularQuestions,
  [BlocksKeys.PROMO_BLOCK]: Benefits,
  [BlocksKeys.REVIEW_BLOCK]: Review,
  [BlocksKeys.SINGLE_SVG_IMAGE]: SingleImage,
  [BlocksKeys.TEXT_BENEFITS]: BenefitsMultipleText,
}

export enum ProviderType {
  Block_content = 'block_content',
}
